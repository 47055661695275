import React from "react";

import "./aboutus.css";

function AboutCompany() {
    return (
        <>
            <section className="about-us-banner">
                <div className="banner-container">
                    <picture>
                        <source media="(min-width: )" srcset="/images/aboutus.jpg" />
                        <img src="/images/aboutus.jpg" alt="About Us" />
                    </picture>
                    <div className="banner-content-overlay">
                        <h1> Welcome to NimboNexus!</h1>
                        <p>  We are a thriving squad of nerd minds, think tankers, pro coders, business geeks, and awesomely creative beings.</p>
                    </div>
                </div>
            </section>

            <div className="our-container">
    <div className="our-row d-flex">
        <div className="our-col d-flex align-items-center" style={{ flex: "1" }}>
            <div className="our-section-content-wrapper">
                <div className="our-section">
                    <h2 className="our-section-title">Who We Are</h2>
                    <p className="our-section-content">NimboNexus is composed of dedicated and skilled professionals focused on driving business transformation through innovative technology. We specialize in implementing and customizing Salesforce, developing applications, integrating data, and automating processes. Our extensive industry experience and technical proficiency allow us to deliver customized solutions that align with the specific needs of each client.</p>
                </div>
                <img src="/images/6.jpg" alt="Who We Are" className="our-section-image mr-4" />
            </div>
        </div>
        <div className="our-col d-flex align-items-center" style={{ flex: "1" }}>
            <div className="our-section-content-wrapper">
                <img src="/images/4.jpg" alt="Our Approach" className="our-section-image mr-4" />
                <div className="our-section">
                    <h2 className="our-section-title">Our Approach</h2>
                    <p className="our-section-content">We prioritize a tailored approach to business solutions. Recognizing that each business faces unique challenges and objectives, we invest the effort to fully grasp your specific requirements. This enables us to provide custom solutions that not only resolve your immediate concerns but also set the stage for your long-term success.</p>
                </div>
            </div>
        </div>
    </div>
</div>


            <div id="servicess">
                <div className="cnts">
                    <div className="rows">
                        <h2 style={{fontSize: 40, textAlign: 'center', paddingBottom: 20}}>Our Services</h2>
                        <div className="reasons__wrappers">
                            <div className="reasons__items">
                                <span className="reasons__item_figures"></span>
                                <div className="reasons__item_contents">
                                    <h3 className="reasons__item_titles">Salesforce Implementation and Customization</h3>
                                    <p className="reasons__item_texts">
                                        <p> We design and deploy Salesforce solutions that align with your business processes, ensuring a seamless integration that enhances efficiency and effectiveness.</p>
                                    </p>
                                </div>
                            </div>
                            <div className="reasons__items">
                                <span className="reasons__item_figures"></span>
                                <div className="reasons__item_contents">
                                    <h3 className="reasons__item_titles">App Development</h3>
                                    <p className="reasons__item_texts">
                                        <p>Our development team creates robust, scalable, and user-friendly applications tailored to your business requirements.</p>
                                    </p>
                                </div>
                            </div>
                            <div className="reasons__items">
                                <span className="reasons__item_figures"></span>
                                <div className="reasons__item_contents">
                                    <h3 className="reasons__item_titles">Data Integration</h3>
                                    <p className="reasons__item_texts">
                                        <p> We facilitate smooth and secure data integration, ensuring that your systems communicate effectively and provide you with accurate, real-time information.</p>
                                    </p>
                                </div>
                            </div>
                            <div className="reasons__items">
                                <span className="reasons__item_figures"></span>
                                <div className="reasons__item_contents">
                                    <h3 className="reasons__item_titles">Automation</h3>
                                    <p className="reasons__item_texts">
                                        <p>By automating repetitive tasks, we help you save time and reduce errors, allowing your team to focus on strategic activities.</p>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bannerL">
                <div className="container-md">
                    <div className="bannerL__container">
                        <div className="bannerL__wrapper">
                            <h2 className="bannerL__title">Ready to Hire Salesforce Consultant?</h2>
                            <h5 className="bannerL__subtitle">For inquiries or more information, please get in touch with us. Let's embark on this transformative journey together!</h5>
                            <div className="bannerL__button button button-outline contactModalButton">
                                <a href="/contact-us" style={{textDecoration: 'none', color: 'white'}}>Book a Call</a>
                            </div>
                            {/* <div className="bannerL__list">
                                <div className="bannerL__list-item">
                                    <h2 className="bannerL__list-item_num">120+</h2>
                                    <h6 className="bannerL__list-item_text">Delivered projects</h6>
                                </div>
                                <div className="bannerL__list-item">
                                    <h2 className="bannerL__list-item_num">50+</h2>
                                    <h6 className="bannerL__list-item_text">Salesforce certified specialists</h6>
                                </div>
                                <div className="bannerL__list-item">
                                    <h2 className="bannerL__list-item_num">8+</h2>
                                    <h6 className="bannerL__list-item_text">years of experience</h6>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutCompany;