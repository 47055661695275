import React from "react";
import Navbar from "../components/header/navigation/Navbar";
import Footer from "../components/footer/Footer";
import ContactUs from "../components/contact-us/ContactUs";
import Contact from "../components/contact-us/Contact";

function ContactForm() {
    return (
        <>
            <Navbar />
            <Contact />
            <ContactUs />
            <Footer />
        </>
    )
}

export default ContactForm;