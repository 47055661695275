import React from 'react';
import './Development.css';


const Development = () => {
  return (
    <>
  <div
    className="hero">
    <div className="hero-container">
      <div className="hero__content">
        <h1
          className="hero__content_title"
          style={{ color: "rgb(0, 107, 149)" }}
        >
          Salesforce Custom Development Services
        </h1>
        <p className="hero__content_text">
          At Saasverse Pvt Ltd, we specialize in delivering tailored Salesforce
          solutions that perfectly align with your unique business requirements.
        </p>
        <div className="hero__content_button button button-solid contactModalButton">
          <a
            href="contact-us"
            style={{ textDecoration: "none", color: "white" }}
          >
            Get a quote
          </a>
        </div>
        <div className="hero__content_logos">
          <a href="/" target="_blank">
            {" "}
            <img
              className="hero_content_logos-item hero_content_logos-item-1"
              src="/assets/images/salesforcepartner.png"
              alt="saasverse"
              style={{ height: 70, borderRadius: 10 }}
            />
          </a>
        </div>
      </div>
      <div className="hero__image">
        <picture className="hire-cns" />
      </div>
    </div>
  </div>
  <div id="services">
    <div className="cnt">
      <div className="row">
        <h2 style={{ fontSize: 40, textAlign: "center" }}>
          Our Salesforce Custom Development Services <br />
        </h2>
        <div className="reasons__wrapper">
          <div className="reasons__item">
            <span className="reasons__item_figure" />
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">
                Workflow and Process Automation
              </h3>
              <p className="reasons__item_text">
                <b style={{ color: "rgb(0, 107, 149)" }}>
                  Automation Solutions:
                </b>
              </p>
              <p>
                Automate repetitive tasks and complex workflows to save time and
                reduce errors.{" "}
              </p>
              <b style={{ color: "rgb(0, 107, 149)" }}>Custom Workflows:</b>
              <p>
                Design and implement custom workflows that align with your
                business processes and improve efficiency.
              </p>
              <p />
            </div>
          </div>
          <div className="reasons__item">
            <span className="reasons__item_figure" />
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">
                Data Integration and Management
              </h3>
              <p className="reasons__item_text">
                <b style={{ color: "rgb(0, 107, 149)" }}>
                  Seamless Integration:
                </b>
              </p>
              <p>
                {" "}
                We integrate Salesforce with other systems and platforms you
                use, ensuring smooth data flow and operational efficiency.
              </p>
              <b style={{ color: "rgb(0, 107, 149)" }}> Data Migration:</b>
              <p>
                {" "}
                Safely migrate your data to Salesforce, maintaining data
                integrity and security throughout the process.
              </p>
              <p />
            </div>
          </div>
          <div className="reasons__item">
            <span className="reasons__item_figure" />
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">
                User Interface Customization
              </h3>
              <p className="reasons__item_text">
                <b style={{ color: "rgb(0, 107, 149)" }}> Intuitive Designs:</b>
              </p>
              <p>
                {" "}
                Design user-friendly and intuitive interfaces that enhance user
                adoption and satisfaction.
              </p>
              <b style={{ color: "rgb(0, 107, 149)" }}> Brand Consistency:</b>
              <p>
                Ensure brand consistency across all Salesforce interfaces,
                creating a cohesive and professional appearance.
              </p>
              <p />
            </div>
          </div>
          <div className="reasons__item">
            <span className="reasons__item_figure" />
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">
                User Interface Customization
              </h3>
              <p className="reasons__item_text">
                <b style={{ color: "rgb(0, 107, 149)" }}> Intuitive Designs:</b>
              </p>
              <p>
                {" "}
                Design user-friendly and intuitive interfaces that enhance user
                adoption and satisfaction.
              </p>
              <b style={{ color: "rgb(0, 107, 149)" }}> Brand Consistency:</b>
              <p>
                Ensure brand consistency across all Salesforce interfaces,
                creating a cohesive and professional appearance.
              </p>
              <p />
            </div>
          </div>
          <div className="reasons__item">
            <span className="reasons__item_figure" />
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">
                Salesforce Einstein Analytics
              </h3>
              <p className="reasons__item_text">
                <b style={{ color: "rgb(0, 107, 149)" }}>
                  Data-Driven Insights:
                </b>
              </p>
              <p>
                Implement Salesforce Einstein Analytics to gain actionable
                insights and make informed business decisions.
              </p>
              <b style={{ color: "rgb(0, 107, 149)" }}> Custom Dashboards:</b>
              <p>
                {" "}
                Create custom dashboards that provide real-time data and metrics
                tailored to your business needs.
              </p>
              <p />
            </div>
          </div>
          <div className="reasons__item">
            <span className="reasons__item_figure" />
            <div className="reasons__item_content">
              <h3 className="reasons__item_title">
                {" "}
                Custom Application Development
              </h3>
              <p className="reasons__item_text">
                <b style={{ color: "rgb(0, 107, 149)" }}> Bespoke Solutions:</b>
              </p>
              <p>
                {" "}
                We develop custom applications tailored to your business
                requirements, ensuring they seamlessly integrate with your
                existing Salesforce environment.
              </p>
              <b style={{ color: "rgb(0, 107, 149)" }}>Advanced Coding:</b>
              <p>
                Our team leverages Salesforce’s Apex and Visualforce to build
                robust and scalable applications.
              </p>
              <p>
                <b style={{ color: "rgb(0, 107, 149)" }}>
                  Lightning Components:
                </b>{" "}
                We create dynamic and responsive Lightning Components to enhance
                the user experience and functionality.
              </p>
              <p />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="bannerL">
    <div className="container-md">
      <div className="bannerL__container">
        <div className="bannerL__wrapper">
          <h2 className="bannerL__title">
            Ready to build your own Salesforce customization?
          </h2>
          <h5 className="bannerL__subtitle">
            Book a call and discuss details with our experts.
          </h5>
          <div className="bannerL__button button button-outline contactModalButton">
            <a
              href="contact-us"
              style={{ textDecoration: "none", color: "white" }}
            >
              Book a Call
            </a>
          </div>
          <div className="bannerL__list">
            <div className="bannerL__list-item">
              <h2 className="bannerL__list-item_num">120+</h2>
              <h6 className="bannerL__list-item_text">Delivered projects</h6>
            </div>
            <div className="bannerL__list-item">
              <h2 className="bannerL__list-item_num">50+</h2>
              <h6 className="bannerL__list-item_text">
                Salesforce certified specialists
              </h6>
            </div>
            <div className="bannerL__list-item">
              <h2 className="bannerL__list-item_num">8+</h2>
              <h6 className="bannerL__list-item_text">years of experience</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="info-container">
    <div className="info-image">
      <img
        src="/assets/images/websiteimages/3.jpg"
        alt="Why Choose Saasverse?"
      />
    </div>
    <div className="info-text">
      <h2>Why Opt for Custom Development?</h2>
      <p>
        Every business is unique, with distinct processes, goals, and
        challenges. Off-the-shelf solutions may not always meet your specific
        needs. That’s where our custom development services come in. With
        Salesforce Custom Development, you can
      </p>
      <p>
        <b style={{ color: "rgb(0, 107, 149)" }}>Bespoke Solutions</b> <br /> We
        develop custom applications tailored to your business requirements,
        ensuring they seamlessly integrate with your existing Salesforce
        environment.
      </p>
      <p>
        <b style={{ color: "rgb(0, 107, 149)" }}>Advanced Coding</b>
        <br />
        Our team leverages Salesforce’s Apex and Visualforce to build robust and
        scalable applications.
      </p>
      <p>
        <b style={{ color: "rgb(0, 107, 149)" }}>Lightning Components</b>
        <br /> We create dynamic and responsive Lightning Components to enhance
        the user experience and functionality.
      </p>
    </div>
  </div>
  <div className="containert">
    <div className="columnt">
      <h2>
        Why Choose <span style={{ color: "rgb(0, 107, 149)" }}>Saasverse?</span>
      </h2>
      <h4>
        Your Salesforce environment holds sensitive business data, customer
        information, and confidential records. Regular security assessments are
        essential to:
      </h4>
    </div>
    <div className="columnt">
      <p>
        <b>Expert Security Specialists</b>
        <br /> Our team of certified security experts brings extensive
        experience in Salesforce security assessments and implementations.
      </p>
      <br />{" "}
      <p>
        <b>Tailored Solutions:</b> <br />
        We provide personalized security recommendations and solutions tailored
        to your specific business needs and industry requirements.
      </p>
    </div>
    <div className="columnt">
      <p>
        <b>Commitment to Excellence</b>
        <br /> We are dedicated to delivering high-quality services that
        prioritize the security and protection of your Salesforce environment.
      </p>
      <br />{" "}
      <p>
        <b>Proven Success</b>
        <br /> Our track record of successful security reviews and satisfied
        clients demonstrates our capability and reliability in ensuring
        Salesforce security.
      </p>
    </div>
  </div>
  <div className="slider-containerc">
    <div className="certitext">
      <h2>
        {" "}
        We Hold Skilled, <b>Well-Trained</b> And <br />
        Certified <b>Salesforce Team</b>
      </h2>
      <div className="slick-slider slick-initialized" dir="ltr">
        <div className="slick-list">
          <div
            className="slick-track"
            style={{
              width: 6800,
              opacity: 1,
              transform: "translate3d(-800px, 0px, 0px)"
            }}
          >
            <div
              data-index={-1}
              tabIndex={-1}
              className="slick-slide slick-cloned"
              aria-hidden="true"
              style={{ width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate8.png"
                    alt="Slide 7"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={0}
              className="slick-slide"
              tabIndex={-1}
              aria-hidden="true"
              style={{ outline: "none", width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate1.png"
                    alt="Slide 0"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={1}
              className="slick-slide slick-active slick-current"
              tabIndex={-1}
              aria-hidden="false"
              style={{ outline: "none", width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate2.png"
                    alt="Slide 1"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={2}
              className="slick-slide"
              tabIndex={-1}
              aria-hidden="true"
              style={{ outline: "none", width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate3.png"
                    alt="Slide 2"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={3}
              className="slick-slide"
              tabIndex={-1}
              aria-hidden="true"
              style={{ outline: "none", width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate4.png"
                    alt="Slide 3"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={4}
              className="slick-slide"
              tabIndex={-1}
              aria-hidden="true"
              style={{ outline: "none", width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate5.png"
                    alt="Slide 4"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={5}
              className="slick-slide"
              tabIndex={-1}
              aria-hidden="true"
              style={{ outline: "none", width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate6.png"
                    alt="Slide 5"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={6}
              className="slick-slide"
              tabIndex={-1}
              aria-hidden="true"
              style={{ outline: "none", width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate7.png"
                    alt="Slide 6"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={7}
              className="slick-slide"
              tabIndex={-1}
              aria-hidden="true"
              style={{ outline: "none", width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate8.png"
                    alt="Slide 7"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={8}
              tabIndex={-1}
              className="slick-slide slick-cloned"
              aria-hidden="true"
              style={{ width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate1.png"
                    alt="Slide 0"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={9}
              tabIndex={-1}
              className="slick-slide slick-cloned"
              aria-hidden="true"
              style={{ width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate2.png"
                    alt="Slide 1"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={10}
              tabIndex={-1}
              className="slick-slide slick-cloned"
              aria-hidden="true"
              style={{ width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate3.png"
                    alt="Slide 2"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={11}
              tabIndex={-1}
              className="slick-slide slick-cloned"
              aria-hidden="true"
              style={{ width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate4.png"
                    alt="Slide 3"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={12}
              tabIndex={-1}
              className="slick-slide slick-cloned"
              aria-hidden="true"
              style={{ width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate5.png"
                    alt="Slide 4"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={13}
              tabIndex={-1}
              className="slick-slide slick-cloned"
              aria-hidden="true"
              style={{ width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate6.png"
                    alt="Slide 5"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={14}
              tabIndex={-1}
              className="slick-slide slick-cloned"
              aria-hidden="true"
              style={{ width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate7.png"
                    alt="Slide 6"
                  />
                </div>
              </div>
            </div>
            <div
              data-index={15}
              tabIndex={-1}
              className="slick-slide slick-cloned"
              aria-hidden="true"
              style={{ width: 400 }}
            >
              <div>
                <div
                  className="slick-slidec"
                  tabIndex={-1}
                  style={{ width: "100%", display: "inline-block" }}
                >
                  <img
                    src="/assets/images/certificateImage/certificate8.png"
                    alt="Slide 7"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="trusted-partner">
    <h2>
      Trusted by global business and <br />
      respected partners
    </h2>
    <div className="trusted-partner__grid">
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/Databricks.png" alt="Partner 1" />
      </div>
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/Doordash.png" alt="Partner 2" />
      </div>
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/Elkay.png" alt="Partner 3" />
      </div>
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/Five9.png" alt="Partner 4" />
      </div>
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/IngersolRand.png" alt="Partner 5" />
      </div>
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/Lafarge.png" alt="Partner 6" />
      </div>
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/Lkpackaging.png" alt="Partner 7" />
      </div>
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/Marlab.png" alt="Partner 8" />
      </div>
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/SYSCO.png" alt="Partner 9" />
      </div>
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/Telstra.png" alt="Partner 10" />
      </div>
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/XPO.png" alt="Partner 11" />
      </div>
      <div className="trusted-partner__item">
        <img src="/assets/images/ourclients/origin.png" alt="Partner 12" />
      </div>
      <div className="trusted-partner__item">
        <img
          src="/assets/images/ourclients/SpringEducationGroup.png"
          alt="Partner 13"
        />
      </div>
    </div>
  </section>
  <div className="cnt_banner">
    <div className="banner__container">
      <h2 className="banner__heading">Ready to Transform Your Business?</h2>
      <div className="banner__text-wrapper">
        <div className="banner__text-content">
          <p>
            Partner with Saasverse Pvt Ltd for top-notch Salesforce Custom
            Development services and take your business to new heights. Contact
            us today to learn how we can help you leverage the power of
            Salesforce to achieve your business goals.
          </p>
        </div>
        <div className="banner__button-wrapper">
          <div className="banner__button button button-outline contactModalButton">
            <a
              href="contact-us"
              style={{ textDecoration: "none", color: "white" }}
            >
              Book a Call
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="faq-container">
    <h2 className="faq-title" style={{ color: "black" }}>
      FAQ's
    </h2>
    <div className="faq-collapse">
      <div
        className="faq-accordion"
        itemScope=""
        itemType="https://schema.org/FAQPage"
      >
        <div
          className="faq-card"
          itemScope=""
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <div className="faq-card-title " itemProp="name">
            Why hire Salesforce Consultants?
          </div>
          <div
            className="faq-card-content "
            itemProp="acceptedAnswer"
            itemScope=""
            itemType="https://schema.org/Answer"
          >
            <p className="faq-card-text" itemProp="text">
              Hiring Salesforce Consultants will make sure that your Salesforce
              implementation is perfectly aligned with your business goals and
              delivers maximum ROI.
            </p>
          </div>
        </div>
        <div
          className="faq-card"
          itemScope=""
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <div className="faq-card-title " itemProp="name">
            Can you customize Salesforce to fit my business needs?
          </div>
          <div
            className="faq-card-content "
            itemProp="acceptedAnswer"
            itemScope=""
            itemType="https://schema.org/Answer"
          >
            <p className="faq-card-text" itemProp="text">
              Absolutely. Our Consultants specialize in customizing Salesforce
              solutions – be it CRM, Marketing Cloud, Mobile App development, or
              CDP – to meet your specific business requirements.
            </p>
          </div>
        </div>
        <div
          className="faq-card"
          itemScope=""
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <div className="faq-card-title " itemProp="name">
            What's the typical engagement process with Saasverse Consultants?
          </div>
          <div
            className="faq-card-content "
            itemProp="acceptedAnswer"
            itemScope=""
            itemType="https://schema.org/Answer"
          >
            <p className="faq-card-text" itemProp="text">
              Our engagement process is client-centric, starting with
              understanding your business needs, followed by providing strategic
              Salesforce consultancy tailored to those needs. We work either on
              a project basis or time &amp; material contracts.
            </p>
          </div>
        </div>
        <div
          className="faq-card"
          itemScope=""
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <div className="faq-card-title " itemProp="name">
            How long does it take to hire Saasverse Consultants?
          </div>
          <div
            className="faq-card-content "
            itemProp="acceptedAnswer"
            itemScope=""
            itemType="https://schema.org/Answer"
          >
            <p className="faq-card-text" itemProp="text">
              Our efficient hiring process ensures that you can quickly onboard
              a skilled Salesforce Consultant, often within a few days to a few
              weeks, depending on your specific requirements.
            </p>
          </div>
        </div>
        <div
          className="faq-card"
          itemScope=""
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <div className="faq-card-title " itemProp="name">
            Can Saasverse provide ongoing support after implementation?
          </div>
          <div
            className="faq-card-content "
            itemProp="acceptedAnswer"
            itemScope=""
            itemType="https://schema.org/Answer"
          >
            <p className="faq-card-text" itemProp="text">
              Yes, we offer ongoing support and consultancy to ensure your
              Salesforce ecosystem continues to evolve with your business,
              delivering continual value.
            </p>
          </div>
        </div>
        <div
          className="faq-card"
          itemScope=""
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <div className="faq-card-title " itemProp="name">
            How do I get started with hiring Salesforce Consultants from
            Saasverse?
          </div>
          <div
            className="faq-card-content "
            itemProp="acceptedAnswer"
            itemScope=""
            itemType="https://schema.org/Answer"
          >
            <p className="faq-card-text" itemProp="text">
              Starting is simple. Contact us, and we'll guide you through our
              straightforward process to connect you with the ideal Salesforce
              consultant for your needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</>
);

};

export default Development;
