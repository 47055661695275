import React from 'react';
import './Salesforce.css';

const Salesforce = () => {
  return (
    <>
      <div id="root">
        <div>
          <div className="hero">
            <div className="hero-container">
              <div className="hero__content">
                <h1 className="hero__content_title" style={{color:'rgb(0, 107, 149)'}}>Salesforce Consulting Company</h1>
                <p className="hero__content_text">
                  At NimboNexus Pvt Ltd, we are dedicated to helping your business unlock its full potential with our comprehensive Salesforce Consulting Services.
                </p>
                <div className="hero__content_button button button-solid contactModalButton">
                  <a href="contact-us" className="hero__content_button-link" style={{ textDecoration:'none',color: 'white'}}>Get a quote</a>
                </div>
                <div className="hero__content_logos"><a href="https://appexchange.salesforce.com/appxConsultingListingDetail?listingId=bf4cc5b0-f7c4-4d44-99ac-1ddaa2ca27e1" target="_blank" rel="noopener noreferrer">
                    <div className="hero__content_logos-item hero__content_logos-item-1" style={{ backgroundImage: `url('/assets/images/salesforcepartner.png')` }}></div>
                  </a>
                </div>
              </div>
              <div className="hero__image">
                <picture className="hire-cns"></picture>
              </div>
            </div>
          </div>

          <div id="services">
            <div className="cnt">
              <div className="row">
                <h2 style={{ fontSize: '40px', textAlign: 'center' }}>

                  Our Salesforce Consulting Covers the Following
                </h2>
                <div className="reasons__wrapper">
                  <div className="reasons__item">
                    <span className="reasons__item_figure"></span>
                    <div className="reasons__item_content">
                      <h3 className="reasons__item_title">
                        Salesforce <br /> Implementation
                      </h3>
                      <p className="reasons__item_text">
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Strategic Planning:</b>
                        <p>
                          We begin by understanding your business goals and processes to create a strategic implementation plan that aligns with your objectives.
                        </p>
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Custom Configuration:</b>
                        <p>
                          Our team configures Salesforce to meet your specific requirements, ensuring a seamless integration with your existing systems.
                        </p>
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Data Migration:</b>
                        <p>
                          We ensure a smooth transition of your data to Salesforce, maintaining data integrity and security throughout the process.
                        </p>
                      </p>
                    </div>
                  </div>

                  <div className="reasons__item">
                    <span className="reasons__item_figure"></span>
                    <div className="reasons__item_content">
                      <h3 className="reasons__item_title">
                        Salesforce <br /> Customization
                      </h3>
                      <p className="reasons__item_text">
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Tailored Solutions:</b>
                        <p>
                          We customize Salesforce to fit your unique business processes, from custom objects and fields to workflow rules and validation.
                        </p>
                        <b style={{ color: 'rgb(0, 107, 149)' }}>App Development:</b>
                        <p>
                          Our developers create custom applications within Salesforce to enhance functionality and provide additional value to your operations.
                        </p>
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Integration Services:</b>
                        <p>
                          We integrate Salesforce with other systems and platforms you use, ensuring a unified and efficient workflow.
                        </p>
                      </p>
                    </div>
                  </div>

                  <div className="reasons__item">
                    <span className="reasons__item_figure"></span>
                    <div className="reasons__item_content">
                      <h3 className="reasons__item_title">
                        Salesforce Support and Maintenance
                      </h3>
                      <p className="reasons__item_text">
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Ongoing Support:</b>
                        <p>
                          Our support team is always available to help you with any issues, ensuring that your Salesforce environment runs smoothly.
                        </p>
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Regular Updates:</b>
                        <p>
                          We keep your Salesforce system up to date with the latest features and security updates, maximizing its performance and reliability.
                        </p>
                        <b style={{ color: 'rgb(0, 107, 149)' }}>User Training:</b>
                        <p>
                          We provide comprehensive training to your team, empowering them to utilize Salesforce effectively and efficiently.
                        </p>
                      </p>
                    </div>
                  </div>

                  <div className="reasons__item">
                    <span className="reasons__item_figure"></span>
                    <div className="reasons__item_content">
                      <h3 className="reasons__item_title">
                        Salesforce <br /> Development
                      </h3>
                      <p className="reasons__item_text">
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Custom Development:</b>
                        <p>
                          Our developers build custom features and applications tailored to your business needs, enhancing the capabilities of your Salesforce platform.
                        </p>
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Apex and Visualforce Development:</b>
                        <p>
                          We use Salesforce’s powerful programming languages to create advanced solutions and custom user interfaces.
                        </p>
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Lightning Components:</b>
                        <p>
                          Our team develops responsive and dynamic Lightning Components to improve user experience and productivity.
                        </p>
                      </p>
                    </div>
                  </div>

                  <div className="reasons__item">
                    <span className="reasons__item_figure"></span>
                    <div className="reasons__item_content">
                      <h3 className="reasons__item_title">
                        Salesforce <br /> Consulting and Strategy
                      </h3>
                      <p className="reasons__item_text">
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Business Process Analysis:</b>
                        <p>
                          We analyze your business processes to identify areas where Salesforce can add value and drive improvements.
                        </p>
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Roadmap Development:</b>
                        <p>
                          We develop a strategic roadmap for your Salesforce journey, ensuring that you achieve your long-term business goals.
                        </p>
                        <b style={{ color: 'rgb(0, 107, 149)' }}>Performance Optimization:</b>
                        <p>
                          Our consultants work with you to optimize your Salesforce environment for better performance and scalability.
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bannerL">
            <div className="container-md">
              <div className="bannerL__container">
                <div className="bannerL__wrapper">
                  <h2 className="bannerL__title">Ready to Hire Salesforce Consultant?</h2>
                  <h5 className="bannerL__subtitle">
                    Enhance your Salesforce experience with expert consultancy. Hire a Salesforce Consultant from NimboNexus now and unlock the full potential of your CRM!
                  </h5>
                  <div className="bannerL__button button button-outline contactModalButton">
                    <a href="/contact-us" style={{ textDecoration: 'none', color: 'white' }}>Book a Call</a>
                  </div>
                  <div className="bannerL__list">
                    <div className="bannerL__list-item">
                      <h2 className="bannerL__list-item_num">120+</h2>
                      <h6 className="bannerL__list-item_text">Delivered projects</h6>
                    </div>
                    <div className="bannerL__list-item">
                      <h2 className="bannerL__list-item_num">50+</h2>
                      <h6 className="bannerL__list-item_text">Salesforce certified specialists</h6>
                    </div>
                    <div className="bannerL__list-item">
                      <h2 className="bannerL__list-item_num">8+</h2>
                      <h6 className="bannerL__list-item_text">years of experience</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="info-container">
      <div className="info-text">
        <h2>Why Choose NimboNexus?</h2>
        <p><b style={{ color: 'rgb(0, 107, 149)' }}>Expertise and Experience: </b><br />Our team of certified Salesforce professionals brings extensive experience and deep expertise to every project.</p>
        <p><b style={{ color: 'rgb(0, 107, 149)' }}>Client-Centric Approach: </b><br />We prioritize understanding your unique needs and delivering solutions that are tailored to your business.</p>
        <p><b style={{ color: 'rgb(0, 107, 149)' }}>Commitment to Excellence:</b><br />We are dedicated to providing exceptional service and ensuring that our clients achieve outstanding results with Salesforce.</p>
        <p><b style={{ color: 'rgb(0, 107, 149)' }}>Proven Track Record:</b><br />Our successful track record speaks for itself, with numerous satisfied clients and successful Salesforce implementations across various industries.</p>
      </div>
      <div className="info-image">
        <img src="/assets/images/websiteimages/4.jpg" alt="Why Choose NimboNexus?" />
      </div>

      <div className="slider-containerc">
        <div className="certitext">
          <h2>We Hold Skilled, <b>Well-Trained</b> And <br />Certified <b>Salesforce Team</b></h2>
          <div className="slick-slider slick-initialized" dir="ltr">
            <button type="button" className="slick-arrow slick-prev" style={{ display: 'block' }}>Previous</button>
            <div className="slick-list">
              <div className="slick-track" style={{ width: '5400px', opacity: 1, transform: 'translate3d(-900px, 0px, 0px)' }}>
                <div data-index="-2" className="slick-slide slick-cloned" style={{ width: '300px' }}>
                  <div>
                    <div className="slick-slide" style={{ width: '100%', display: 'inline-block' }}>
                      <img src="/assets/images/certificateImage/certificate7.png" alt="Slide 6" />
                    </div>
                  </div>
                </div>
                 
                {/* Slide 1 */}
                <div data-index="0" className="slick-slide" style={{ width: '300px' }}>
                  <div>
                    <div className="slick-slidec" style={{ width: '100%', display: 'inline-block' }}>
                      <img src="/assets/images/certificateImage/certificate1.png" alt="Slide 1" />
                    </div>
                  </div>
                </div>
                
                {/* Slide 2 */}
                <div data-index="1" className="slick-slide" style={{ width: '300px' }}>
                  <div>
                    <div className="slick-slidec" style={{ width: '100%', display: 'inline-block' }}>
                      <img src="/assets/images/certificateImage/certificate2.png" alt="Slide 2" />
                    </div>
                  </div>
                </div>
                
                {/* Slide 3 */}
                <div data-index="2" className="slick-slide" style={{ width: '300px' }}>
                  <div>
                    <div className="slick-slidec" style={{ width: '100%', display: 'inline-block' }}>
                      <img src="/assets/images/certificateImage/certificate3.png" alt="Slide 3" />
                    </div>
                  </div>
                </div>
                
                {/* Slide 4 */}
                <div data-index="3" className="slick-slide" style={{ width: '300px' }}>
                  <div>
                    <div className="slick-slidec" style={{ width: '100%', display: 'inline-block' }}>
                      <img src="/assets/images/certificateImage/certificate4.png" alt="Slide 4" />
                    </div>
                  </div>
                </div>
                
                {/* Slide 5 */}
                <div data-index="4" className="slick-slide" style={{ width: '300px' }}>
                  <div>
                    <div className="slick-slidec" style={{ width: '100%', display: 'inline-block' }}>
                      <img src="/assets/images/certificateImage/certificate5.png" alt="Slide 5" />
                    </div>
                  </div>
                </div>
                
                {/* Slide 6 */}
                <div data-index="5" className="slick-slide" style={{ width: '300px' }}>
                  <div>
                    <div className="slick-slidec" style={{ width: '100%', display: 'inline-block' }}>
                      <img src="/assets/images/certificateImage/certificate6.png" alt="Slide 6" />
                    </div>
                  </div>
                </div>
                
                {/* Cloned Slides */}
                <div data-index="-2" className="slick-slide slick-cloned" style={{ width: '300px' }}>
                  <div>
                    <div className="slick-slidec" style={{ width: '100%', display: 'inline-block' }}>
                      <img src="/assets/images/certificateImage/certificate7.png" alt="Slide 7" />
                    </div>
                  </div>
                </div>
                
                <div data-index="-1" className="slick-slide slick-cloned" style={{ width: '300px' }}>
                  <div>
                    <div className="slick-slidec" style={{ width: '100%', display: 'inline-block' }}>
                      <img src="/assets/images/certificateImage/certificate8.png" alt="Slide 8" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" className="slick-arrow slick-next" style={{ display: 'block' }}>Next</button>
          </div>
        </div>
      </div>
                

      {/* <section className="trusted-partner">
        <h2>Trusted by global business and <br />respected partners</h2>
        <div className="trusted-partner__grid">
          <div className="trusted-partner__item">
            <img src="/assets/images/ourclients/Databricks.png" alt="Partner 1" />
          </div>
          <div className="trusted-partner__item">
            <img src="/assets/images/ourclients/Doordash.png" alt="Partner 2" />
          </div>
          <div className="trusted-partner__item">
            <img src="/assets/images/ourclients/Elkay.png" alt="Partner 3" />
          </div>
          <div className="trusted-partner__item">
            <img src="/assets/images/ourclients/Elkay.png" alt="Partner 4" />
          </div>
          <div className="trusted-partner__item">
            <img src="/assets/images/ourclients/Elkay.png" alt="Partner 5" />
          </div>
          <div className="trusted-partner__item">
            <img src="/assets/images/ourclients/Elkay.png" alt="Partner 6" />
          </div>
          <div className="trusted-partner__item">
            <img src="/assets/images/ourclients/Elkay.png" alt="Partner 3" />
          </div>
        </div>
      </section> */}

      <div className="cnt_banner">
        <div className="banner__container">
          <h2 className="banner__heading">Ready to Transform Your Business?</h2>
          <div className="banner__text-wrapper">
            <div className="banner__text-content">
              <p>Partner with NimboNexus Pvt Ltd for top-notch Salesforce Consulting Services and take your business to new heights. Contact us today to learn how we can help you leverage the power of Salesforce to achieve your business goals.</p>
            </div>
            <div className="banner__button-wrapper">
              <div className="banner__button button button-outline contactModalButton">
                <a href="contact-us" style={{ textDecoration: 'none', color: 'white' }}>Book a Call</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq-container">
      <h2 className="faq-title" style={{ color: 'black' }}>FAQ's</h2>
      <div className="faq-collapse">
        <div className="faq-accordion">
          <div className="faq-card">
            <div className="faq-card-title">Why hire Salesforce Consultants?</div>
            <div className="faq-card-content">
              <p className="faq-card-text">Hiring Salesforce Consultants will make sure that your Salesforce implementation is perfectly aligned with your business goals and delivers maximum ROI.</p>
            </div>
          </div>
          <div className="faq-card">
            <div className="faq-card-title">Can you customize Salesforce to fit my business needs?</div>
            <div className="faq-card-content">
              <p className="faq-card-text">Absolutely. Our Consultants specialize in customizing Salesforce solutions – be it CRM, Marketing Cloud, Mobile App development, or CDP – to meet your specific business requirements.</p>
            </div>
          </div>
          <div className="faq-card">
            <div className="faq-card-title">How long does a Salesforce implementation take?</div>
            <div className="faq-card-content">
              <p className="faq-card-text">The timeline for Salesforce implementation varies depending on the complexity and scope of the project. However, our team ensures a timely and efficient implementation process.</p>
            </div>
          </div>
          <div className="faq-card">
            <div className="faq-card-title">What kind of support do you provide after Salesforce implementation?</div>
            <div className="faq-card-content">
              <p className="faq-card-text">We offer comprehensive post-implementation support, including ongoing maintenance, troubleshooting, and optimization services to ensure your Salesforce environment runs smoothly.</p>
            </div>
          </div>
          <div className="faq-card">
            <div className="faq-card-title">Do you provide Salesforce training for my team?</div>
            <div className="faq-card-content">
              <p className="faq-card-text">Yes, we provide tailored Salesforce training sessions to help your team effectively utilize the platform and maximize its potential for your business.</p>
            </div>
          </div>
          <div className="faq-card">
            <div className="faq-card-title">Can you integrate Salesforce with other systems?</div>
            <div className="faq-card-content">
              <p className="faq-card-text">Definitely. We have expertise in integrating Salesforce with various third-party systems and applications to streamline your business processes.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  );
};

export default Salesforce;
