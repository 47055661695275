import React from "react";
import Navbar from "../components/header/navigation/Navbar";
import Carouselbar from "../components/slider/Carouselbar";
import AboutCompany from "../components/aboutus/Aboutcompany";
import Services from "../components/service/Services";
import Footer from "../components/footer/Footer";



function Dashboard () {
    return (
        <>
            <Navbar />

            <Carouselbar />

            <AboutCompany />

          

            <Services />

            
            <Footer />
        </>
    )
}

export default Dashboard;