import React from "react";
import Navbar from "../components/header/navigation/Navbar";
import Footer from "../components/footer/Footer";
import Aboutus from "../components/aboutuspage/Aboutus";

function AboutUs() {
    return (
        <>
            <Navbar />
            <Aboutus />
            <Footer />
        </>
    )
}

export default AboutUs;