import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Valuessvg from "./Valuesvg";
import CultureSvg from "./culturesvg";
import GrowthSvg from "./growthsvg";

import "./footer.css";


library.add(fab)

export default function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-row">
                    <div className="footer-column">
                        <h5>Our Core Services</h5>
                        <ul className="list-unstyled footer-list">
                            <a href="/salesforce-ex">
                                <li>Salesforce Consulting Services</li>
                            </a>
                            <a href="/salesforce-isv">
                                <li>Salesforce Integration Services </li>
                            </a>
                            <a href="/salesforce-managed">
                                <li>Salesforce Custom Development</li>
                            </a>
                            <a href="/salesforce-consulting">
                                <li>Salesforce Security Review</li>
                            </a>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h5>Our Expertise</h5>
                        <ul className="list-unstyled footer-list">
                            <a href="/expertise/salesforce-sales-cloud/">
                                <li>Sales Cloud</li>
                            </a>
                            <a href="/expertise/salesforce-service-cloud/"><li>Service Cloud</li></a>
                            <a href="/expertise/salesforce-marketing-cloud/"><li>Experience Cloud</li></a>
                            <a href="/expertise/health-cloud-services/"><li>Omni studio</li></a>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h5>Company</h5>
                        <ul className="list-unstyled footer-list">
                            <a href="/about-us/"><li>About Us</li></a>
                            <a href="/contact-us/"><li>Contact</li></a>
                            <a href="/Services/"><li>Services</li></a>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <img src="/images/lastlogo.png" alt="Company Logo" className="footer-logo" style={{marginTop: -15}} />
                        <address className="footer-address">
                            <div className="add">
    
                                <p style={{fontSize: 25}}>INDIA</p>
                                <p style={{fontSize: 20}}>
                                    <a href="tel:+91-7976066296" style={{textDecoration: "none", color: "white"}}>+91-7976066296</a>
                                </p>
                            </div>
                            <div style={{marginTop: 10}}> Email:<a href="mailto:contact@sasverse.in"> contact@nimbonexus.com</a></div>
                        </address>
                        <div className="footer-social">
                            <a rel="noopener noreferrer" href="https://www.linkedin.com/company/nimbonexus-pvt-ltd/" target="_blank">
                                <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" />
                            </a>
                            {/* <a rel="noopener noreferrer" href="https://www.instagram.com/nimbonexus.com/" target="_blank">
                                <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
                            </a>
                            <a rel="noopener noreferrer" href="https://www.facebook.com/nimbonexus.com" target="_blank">
                                <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" />
                            </a> */}
                        </div>
                    </div>
                </div>
                <hr className="footer-divider" />
                <div className="footer-values-row">
                    <div className="footer-values-column">
                        <ul className="list-unstyled footer-values-list">
                            <h3>Our Values</h3>
                            <li>
                                <Valuessvg fontSize={15} />Give Back To The Community
                            </li>
                            <li>
                                <CultureSvg />Foster a Culture of Caring
                            </li>
                            <li>
                                <CultureSvg />Deliver Seamless Experiences &amp; Best in-Class Solutions
                            </li>
                            <li>
                                <GrowthSvg />Embrace Change &amp; Strive for Growth
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="footer-divider" />
                <div className="footer-copyright">© 2024 NimboNexus PVT LTD. All rights reserved.</div>
            </div>
        </footer>
    )
}