import React from "react";
import Navbar from "../components/header/navigation/Navbar";
import Footer from "../components/footer/Footer";
import Development from "../components/development/Development";

function DevelopmentPage() {
    return (
        <>
            <Navbar />
            <Development />
            <Footer />
        </>
    );
}

export default DevelopmentPage;
