import { Route, Routes }from "react-router-dom";
import Dashboard from './pages/Dashboard';
import AboutUs from "./pages/AboutUs";
import history from "./components/common/history";
import Salesforce from "./pages/Salesforce"
import Development from "./pages/Development"
import Training from "./pages/Training"
import Contact from "./pages/Contact"
// import Blog from "./pages/Blog"

import logo from './logo.svg';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';

function App() {

  return (
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/salesforce" element={<Salesforce />} />
        <Route exact path="/development" element={<Development />} />
        <Route exact path="/training" element={<Training />} />
        <Route exact path="/Contact" element={<Contact />} />
        {/* <Route exact path="/Blog" element={<Blog />} /> */}
      </Routes>
  );
}

export default App;
