import React from "react";
import Navbar from "../components/header/navigation/Navbar";
import Footer from "../components/footer/Footer";
import Training from "../components/training/Training"

function SalesforcePage() {
    return (
        <>
            <Navbar />
            <Training />
            <Footer />
        </>
    );
}

export default SalesforcePage;