import React from "react"

export default function CultureSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.34398 11.8315V19.993H6.67615V12.1684C6.67615 11.02 7.83988 10.6984 8.40644 11.3568C9.12611 12.1837 8.20738 16.8999 8.94236 18.8139C9.04955 19.1048 9.14142 19.2886 9.27923 19.5642C10.4889 22.0754 10.6727 24.4794 10.6727 27.3428H4.34868C4.31806 27.0672 4.07306 26.5007 3.95057 26.2404L2.57246 22.9482C2.2509 22.2132 1.97528 21.5242 1.73029 20.7126C0.934048 18.0483 1.45467 15.874 2.3734 13.4699C2.90933 12.0765 3.39932 10.775 3.95057 9.38155C4.50181 7.98813 4.97649 6.64065 5.51242 5.27786C5.7421 4.696 5.97178 3.7007 6.53834 3.47101C7.33458 3.16477 8.08488 3.68539 8.08488 4.52756C8.08488 4.86443 6.8599 7.54408 6.64552 8.08001C6.18616 9.25905 5.34398 10.729 5.34398 11.8468V11.8315ZM0.0153123 17.4052C0.0153123 21.2486 0.704364 21.8611 2.06716 25.2757C2.22028 25.6585 2.32746 25.9035 2.48059 26.271C2.64902 26.6691 2.7409 26.96 2.92464 27.3122C2.35809 27.3122 2.00591 27.4194 2.00591 27.986V39.9755H3.33807V28.6444H11.3311V39.9755H12.6632V27.986C12.6632 27.5572 12.4029 27.3582 11.9895 27.3122C11.9895 23.8363 11.9129 22.0754 10.5655 19.0742C10.4123 18.7527 10.2439 18.477 10.1367 18.0942C9.8611 17.0989 9.9836 14.1437 9.9836 12.7503C9.9836 11.6631 10.0142 11.1118 9.46298 10.5147C8.91174 9.93279 8.59018 9.65717 7.4877 9.65717C7.57957 9.24374 7.82457 8.78437 7.993 8.40157C8.17675 7.97282 8.34519 7.5747 8.52893 7.10002C9.17205 5.5688 10.1214 4.17538 8.80455 2.75134C7.79394 1.64885 5.9871 1.69479 4.97649 2.96571C4.62431 3.39445 2.90933 8.0647 2.60309 8.92218L1.85278 10.9128C1.59248 11.5865 1.34748 12.2603 1.08717 12.9034C0.612491 14.0824 0 15.7668 0 17.3899L0.0153123 17.4052Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M31.9108 4.4969C31.9108 3.67004 32.6152 3.16473 33.3961 3.42504C34.0392 3.63941 34.2077 4.51221 34.468 5.18595C35.0498 6.65593 38.051 14.4192 38.3267 15.4911C38.7554 17.1448 38.7707 19.0436 38.2654 20.682C37.8367 22.106 36.7036 24.6172 36.0758 26.1638C35.9379 26.4853 35.6776 26.96 35.647 27.3122H29.323C29.323 24.2957 29.4455 22.3357 30.6552 19.656C31.5739 17.6195 31.3136 17.3286 31.3136 14.5724C31.3136 13.2096 30.9461 10.9893 32.3089 10.9893C32.8296 10.9893 33.3042 11.464 33.3042 12.153V19.9776H34.6364V11.8162C34.6364 10.9893 34.3608 10.4687 34.1005 9.85619C33.9627 9.54995 33.8402 9.25901 33.7177 8.98339C33.4114 8.27903 31.8802 4.72658 31.8802 4.48159L31.9108 4.4969ZM30.5021 4.42034C30.5021 5.3697 32.263 8.63121 32.508 9.67244C31.4055 9.67244 31.1299 9.90213 30.5633 10.484C29.9968 11.0659 29.9968 11.5712 29.9968 12.6736C29.9968 14.0671 30.1193 17.0989 29.8437 18.1095C29.614 18.9211 29.0321 19.5795 28.3737 22.1366C27.9449 23.7904 27.9909 25.3063 27.9909 27.3275C27.5774 27.3581 27.3171 27.5572 27.3171 28.0012V39.9907H28.6493V28.6597H36.6423V39.9907H37.9745V28.0012C37.9745 27.45 37.6223 27.3275 37.0557 27.3275C37.3773 26.715 37.6376 25.9647 37.9285 25.2756C38.3267 24.311 39.3526 21.9835 39.5823 21.0954C40.6082 17.0376 39.5976 14.6642 38.1429 11.0046C38.0051 10.6677 37.8826 10.3462 37.7601 9.97869C37.3926 8.93746 35.3714 3.54754 35.0345 3.04223C33.9933 1.51101 31.4821 1.67944 30.7011 3.48629C30.6246 3.65472 30.4868 4.23659 30.4868 4.43565L30.5021 4.42034Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.3369 14.006C13.3369 12.4901 14.4853 11.3416 16.0013 11.3416C17.9919 11.3416 19.0484 14.006 19.9978 14.006C20.9777 14.006 21.9271 11.3416 24.0861 11.3416C25.4949 11.3416 26.6739 12.5054 26.6739 14.006C26.6739 15.1238 25.9849 15.7363 25.403 16.3181C24.6986 17.0225 20.3806 21.4171 20.0131 21.6774C19.6456 21.4324 15.3275 17.0378 14.6232 16.3181C14.0413 15.7363 13.3522 15.1238 13.3522 14.006H13.3369ZM12.0048 13.9294C12.0048 15.3994 12.5407 16.1803 13.3522 16.9919L18.7728 22.4124C20.0284 23.668 19.9518 23.6833 21.1921 22.4583L26.5208 17.1297C26.9495 16.7009 27.2098 16.5019 27.5008 15.9506C29.0933 12.8575 26.6127 10.0401 24.3311 10.0401C23.2133 10.0401 22.4018 10.1932 21.5749 10.8516C21.0543 11.2651 20.5184 12.0154 20.0131 12.3676C19.4925 12.0154 18.9718 11.2804 18.4359 10.8669C15.7256 8.78448 12.0201 10.8823 12.0201 13.9447L12.0048 13.9294Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.2592 3.33307L11.0095 4.32837L12.8316 3.22588C13.3675 2.90433 14.225 2.49089 14.8835 2.27652C17.6856 1.32716 19.6915 1.08217 22.6774 1.58747C24.0555 1.81715 25.9236 2.47558 27.1026 3.16463C27.4701 3.37901 28.787 4.26712 29.0167 4.32837C29.2617 3.96087 29.5373 3.68525 29.767 3.33307C29.2617 2.64402 26.6739 1.41904 25.7858 1.06685C21.4984 -0.648121 16.0931 -0.280626 12.1426 2.06215C11.6679 2.33777 10.5195 2.98089 10.2592 3.34838V3.33307Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.9307 2.9198C17.9307 3.44042 18.0685 3.73135 18.0991 4.17541C18.6503 4.17541 19.3547 4.00697 19.9366 4.00697C22.2793 4.00697 24.5762 4.75727 26.1534 5.70663C27.118 6.2885 28.9096 7.6666 29.4302 8.67721C29.6445 8.57003 30.3948 8.01879 30.5173 7.85035C30.303 7.0388 27.5927 4.9104 26.5208 4.34384C23.9484 3.01167 22.5856 2.6748 19.4466 2.6748C19.2016 2.6748 17.946 2.6748 17.946 2.9198H17.9307Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5054 30.6656C14.0873 30.8034 14.9907 31.3087 16.1851 31.569C19.0485 32.1662 20.8706 32.1662 23.734 31.5843C25.0815 31.3087 25.9849 30.7881 26.4902 30.6809C26.4443 30.0837 26.1227 29.9306 26.0768 29.4253L23.2593 30.2828C21.1003 30.7881 18.88 30.7881 16.721 30.2828L13.9035 29.4253C13.8576 29.9306 13.536 30.0837 13.4901 30.6809L13.5054 30.6656Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0156 27.404C13.2606 27.4653 14.0874 27.94 14.409 28.0931C16.5527 29.1803 19.2936 29.5018 21.7589 29.2262C22.111 29.1803 22.4938 29.0731 22.846 29.0731C22.8154 28.629 22.601 28.2309 22.601 27.7409C22.157 27.7409 21.7589 27.8481 21.4067 27.8787L20.0898 27.9859C18.7577 28.0165 16.7211 27.5725 15.4961 27.0825C14.8224 26.8069 14.2712 26.47 13.674 26.1484C13.429 26.6078 13.0309 27.0825 13.0002 27.404H13.0156Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0979 3.59326C15.2051 4.03732 15.4807 4.45075 15.5113 4.84887C16.0319 4.80293 16.2923 4.52731 16.8435 4.512C16.8282 4.03732 16.6138 3.5167 16.5066 3.10327C16.0932 3.30233 15.5113 3.40952 15.0979 3.60858V3.59326Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.68555 31.3239H6.01771V29.9917H4.68555V31.3239Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M33.9933 31.3239H35.3255V29.9917H33.9933V31.3239Z" fill="white"/>
        </svg>
    )
}