import React from 'react';
import './Training.css';

const Training = () => {
  return (
    <>
      <section className="about-us-banner">
        <div className="banner-container">
            <picture>
                <source media="(min-width: )" srcset="/images/aboutus.jpg" />
                <img src="/images/training.webp" alt="Training" />
            </picture>
            <div className="banner-content-overlay">
            <h1  style={{ textAlign: "center", fontSize: 40, justifyContent: "center" }}>
          Salesforce Training &amp; Certifications
        </h1>
                <p>  At NimboNexus Pvt Ltd, we are committed to helping individuals and
                organizations unlock the full potential of Salesforce.</p>
            </div>
        </div>
      </section>
    <div className="info-container">
      <div className="info-image">
        <img src="" alt="Why Choose NimboNexus?"/>
      </div>
      <div className="info-text">
        <h2> Why Choose NimboNexus for Salesforce Training?</h2>
        <p>
          Investing in Salesforce training ensures that your team can leverage the
          platform's full capabilities, stay updated with the latest features, and
          implement best practices. Our training programs offer
        </p>
        <p>
          <b style={{ color: "rgb(0, 107, 149)" }}>Expert Instructors</b> <br />{" "}
          Learn from certified Salesforce professionals with extensive industry
          experience.
        </p>
        <p>
          <b style={{ color: "rgb(0, 107, 149)" }}>Customized Curriculum</b>
          <br />
          Tailor-made training programs that align with your specific business
          needs and objectives.
        </p>
        <p>
          <b style={{ color: "rgb(0, 107, 149)" }}>Flexible Learning Options</b>
          <br />
          Choose from on-site, online, or hybrid training formats to suit your
          schedule and preferences.
        </p>
        <p>
          <b style={{ color: "rgb(0, 107, 149)" }}>Hands-On Experience</b>
          <br /> Practical, real-world exercises to reinforce learning and ensure
          practical application.
        </p>
      </div>
    </div>
    <section className="trusted-partner">
      <h2>
        Elevating Training Through
        <br /> Accredited Collaborations
      </h2>
      <div className="trusted-partner__grid">
        <div className="trusted-partner__item">
          <img
            src="/assets/images/traininglogoo/CapgeminiLogo.png"
            alt="Partner 1"
          />
        </div>
        <div className="trusted-partner__item">
          <img
            src="/assets/images/traininglogoo/CognizantLogo.png"
            alt="Partner 2"
          />
        </div>
        <div className="trusted-partner__item">
          <img
            src="/assets/images/traininglogoo/FujitsuLogo.png"
            alt="Partner 3"
          />
        </div>
        <div className="trusted-partner__item">
          <img
            src="/assets/images/traininglogoo/genpactlogo.png"
            alt="Partner 4"
          />
        </div>
        <div className="trusted-partner__item">
          <img
            src="/assets/images/traininglogoo/WileyEdgelogo.png"
            alt="Partner 5"
          />
        </div>
        <div className="trusted-partner__item">
          <img src="/assets/images/traininglogoo/ibmlogo.png" alt="Partner 6" />
        </div>
        <div className="trusted-partner__item">
          <img src="/assets/images/traininglogoo/HCLLogo.png" alt="Partner 7" />
        </div>
        <div className="trusted-partner__item">
          <img src="/assets/images/traininglogoo/TataLogo.png" alt="Partner 8" />
        </div>
        <div className="trusted-partner__item">
          <img
            src="/assets/images/traininglogoo/Ilearninglogo.png"
            alt="Partner 9"
          />
        </div>
      </div>
    </section>
    <section
      id="cta-panel-block_52b39b3c30590fd478440d8b1aa809ab"
      className="cta-panel custom-block bg-gradient lozad"
      style={{
        background:
          "linear-gradient(90deg, rgb(0, 107, 149) 0%, rgb(0, 0, 0) 100%)",
        height: "30vh"
      }}
    >
      <div className="cta-panel-inner">
        <div className="left-col">
          <div className="col-inner">
            <p>Empower Your Team with Expert Salesforce Training</p>
          </div>
        </div>
        <div className="right-col">
          <div className="col-inner">
            <a
              href="/contact-us"
              style={{ textDecoration: "none", color: "white" }}
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <div className="bg-logo" />
    </section>
    <section id="training-service" className="training-service">
      <div className="training-service-intro-wrap">
        <div className="training-service-title-bordered">
          <h2 className="training-service-title-with-lines">
            Salesforce Training Services
          </h2>
        </div>
      </div>
      <div className="training-service-flip-cards-container">
        <div className="training-service-flip-cards-wrap">
          <div className="training-service-flip-card-outer">
            <div className="training-service-flip-card">
              <div className="training-service-flip-card-body">
                <div className="training-service-flip-card-front">
                  <div className="training-service-front-inner">
                    <img
                      className="lozad"
                      src="/assets/images/training/trainingicon2.png"
                      alt="icon-end-user-training"
                    />
                    <h3>Salesforce</h3>
                    <h2>End User Training</h2>
                  </div>
                </div>
                <div className="training-service-flip-card-back">
                  <div className="training-service-back-inner">
                    <p>
                      In our experience, investing in effective end user training
                      is one of the most important factors for any successful
                      Salesforce implementation and a major step towards achieving
                      high adoption rates.
                      <br />
                      We’ll tailor your Salesforce training to your precise
                      business requirements, system configuration and the specific
                      needs of your sales, marketing and customer service teams.
                      <br />
                      <br />
                      We confirm what your objectives are and what you want to be
                      able to accomplish by the end of your training and create a
                      bespoke training plan to match.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="training-service-flip-card-outer">
            <div className="training-service-flip-card">
              <div className="training-service-flip-card-body">
                <div className="training-service-flip-card-front">
                  <div className="training-service-front-inner">
                    <img
                      className="lozad"
                      src="/assets/images/training/trainingicon.png"
                      alt="CRM Consultation Logo"
                    />
                    <h3>Salesforce</h3>
                    <h2>Corporate Training</h2>
                  </div>
                </div>
                <div className="training-service-flip-card-back">
                  <div className="training-service-back-inner">
                    <p>
                      A committed Management team is key in the implementation of
                      a successful CRM system. This training provides your
                      managers tools for them to understand the system and
                      implementation.
                      <br />
                      We can provide your managers with best practice advice and
                      training on the Salesforce tools and key performance
                      indicators that will enable them to be more effective in
                      their role.
                      <br />
                      <br />
                      Training on advanced Salesforce reports and custom
                      dashboards will help them unlock valuable business insight
                      to better manage their teams, drive business performance and
                      improve results.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="batch-container">
      <div className="batch-buttons">
        <button className="active">Ongoing Batches</button>
        <button className="">Upcoming Batches</button>
      </div>
      <ul className="batch-list">
        <li className="course-item">
          <div className="course-info">
            <div className="course-header">
              <span className="course-title">
                Salesforce Admin &amp; Development
              </span>
            </div>
            <div className="course-description">
              Begin with Salesforce administration essentials, including user
              management, security, and data handling. Progress to advanced
              features like workflow automation, reporting, and dashboards. For
              development, start with basics like Apex programming, Visualforce,
              and Lightning components, then master advanced skills in
              integration, custom app development, and performance optimization.
            </div>
            <button className="toggle-button">Batch Details +</button>
          </div>
        </li>
        <li className="course-item">
          <div className="course-info">
            <div className="course-header">
              <span className="course-title">Salesforce Admin</span>
            </div>
            <div className="course-description">
              Start with the basics of Salesforce administration, focusing on user
              management, security settings, and data handling. Progress to
              advanced features like workflow automation, reporting, and
              dashboards to effectively manage and optimize Salesforce
              environments.
            </div>
            <button className="toggle-button">Batch Details +</button>
          </div>
        </li>
        <li className="course-item">
          <div className="course-info">
            <div className="course-header">
              <span className="course-title">Salesforce CPQ</span>
            </div>
            <div className="course-description">
              {" "}
              Start with the basics of Salesforce CPQ by learning how to configure
              products, pricing, and quoting. Progress to advanced features such
              as discount management, product rules, and guided selling to create
              accurate and efficient quotes for customers.
            </div>
            <button className="toggle-button">Batch Details +</button>
          </div>
        </li>
      </ul>
    </div>
    <div className="containert">
      <div className="columnt">
        <h2>
          Why Choose <span style={{ color: "rgb(0, 107, 149)" }}>NimboNexus?</span>
        </h2>
        <h4>
          Your Salesforce environment holds sensitive business data, customer
          information, and confidential records. Regular security assessments are
          essential to:
        </h4>
      </div>
      <div className="columnt">
        <p>
          <b>Expert Security Specialists</b>
          <br /> Our team of certified security experts brings extensive
          experience in Salesforce security assessments and implementations.
        </p>
        <br />{" "}
        <p>
          <b>Tailored Solutions:</b> <br />
          We provide personalized security recommendations and solutions tailored
          to your specific business needs and industry requirements.
        </p>
      </div>
      <div className="columnt">
        <p>
          <b>Commitment to Excellence</b>
          <br /> We are dedicated to delivering high-quality services that
          prioritize the security and protection of your Salesforce environment.
        </p>
        <br />{" "}
        <p>
          <b>Proven Success</b>
          <br /> Our track record of successful security reviews and satisfied
          clients demonstrates our capability and reliability in ensuring
          Salesforce security.
        </p>
      </div>
    </div>
    <div className="job-openings">
      <h2 className="job-openings-title">Our Salesforce Training Programs</h2>
      <div className="job-list">
        <div className="job-item">
          <h3 className="job-title" style={{ fontSize: 35, fontWeight: 500 }}>
            Salesforce Admin Training
          </h3>
          <div className="job-details">
            <p>
              <span className="label" />
              Fundamentals: Understand the core concepts of Salesforce
              administration, including user management, security settings, and
              data management.
            </p>
            <a
              className="apply-button"
              href="/"
              style={{ textDecoration: "none" }}
            >
              <b>Apply</b>
            </a>
          </div>
          <div className="job-description">
            <p>
              <span className="label" />
              Advanced Admin: Dive deeper into advanced features like workflow
              automation, reporting, and dashboards.
            </p>
          </div>
        </div>
        <div className="job-item">
          <h3 className="job-title" style={{ fontSize: 35, fontWeight: 500 }}>
            Salesforce Developer Training
          </h3>
          <div className="job-details">
            <p>
              <span className="label" />
              Basic Development: Learn the essentials of Salesforce development,
              including Apex programming, Visualforce, and Lightning components.
            </p>
            <a
              className="apply-button"
              href="/"
              style={{ textDecoration: "none" }}
            >
              <b>Apply</b>
            </a>
          </div>
          <div className="job-description">
            <p>
              <span className="label" />
              Advanced Development: Master advanced topics such as integration,
              custom app development, and performance optimization.
            </p>
          </div>
        </div>
        <div className="job-item">
          <h3 className="job-title" style={{ fontSize: 35, fontWeight: 500 }}>
            Salesforce CPQ Training
          </h3>
          <div className="job-details">
            <p>
              <span className="label" />
              CPQ Basics: Get to grips with the fundamentals of Salesforce CPQ
              (Configure, Price, Quote), including product configuration, pricing
              rules, and quoting processes.
            </p>
            <a
              className="apply-button"
              href="/"
              style={{ textDecoration: "none" }}
            >
              <b>Apply</b>
            </a>
          </div>
          <div className="job-description">
            <p>
              <span className="label" />
              Advanced CPQ: Explore advanced CPQ functionalities, such as advanced
              pricing, discounting strategies, and proposal generation.
            </p>
          </div>
        </div>
        <div className="job-item">
          <h3 className="job-title" style={{ fontSize: 35, fontWeight: 500 }}>
            Salesforce Marketing Cloud Training
          </h3>
          <div className="job-details">
            <p>
              <span className="label" />
              Marketing Cloud Training: Drive marketing success with targeted
              Marketing Cloud training, covering email marketing, automation, and
              analytics.
            </p>
            <a
              className="apply-button"
              href="/"
              style={{ textDecoration: "none" }}
            >
              <b>Apply</b>
            </a>
          </div>
          <div className="job-description">
            <p>
              <span className="label" />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="services">
      <div className="cnt">
        <div className="row">
          <h2 style={{ fontSize: 40, textAlign: "center", paddingBottom: 20 }}>
            Our Training Formats
          </h2>
          <div className="reasons__wrapper">
            <div className="reasons__item">
              <span className="reasons__item_figure" />
              <div className="reasons__item_content">
                <h3 className="reasons__item_title">On-Site Training</h3>
                <p className="reasons__item_text">
                  <b style={{ color: "rgb(0, 107, 149)" }}>
                    Personalized Sessions
                  </b>
                </p>
                <p>
                  {" "}
                  Deliver tailored training programs at your location for a
                  hands-on learning experience.
                </p>
                <b style={{ color: "rgb(0, 107, 149)" }}>Team Building</b>
                <p>
                  Foster collaboration and team building with group training
                  sessions.
                </p>
                <p />
              </div>
            </div>
            <div className="reasons__item">
              <span className="reasons__item_figure" />
              <div className="reasons__item_content">
                <h3 className="reasons__item_title">Online Training</h3>
                <p className="reasons__item_text">
                  <b style={{ color: "rgb(0, 107, 149)" }}>Flexible Scheduling</b>
                </p>
                <p>
                  Access training sessions from anywhere, at any time, with our
                  online learning platform.
                </p>
                <b style={{ color: "rgb(0, 107, 149)" }}>Interactive Learning</b>
                <p>
                  {" "}
                  Engage with instructors through live webinars, interactive
                  modules, and virtual labs.
                </p>
                <p />
              </div>
            </div>
            <div className="reasons__item">
              <span className="reasons__item_figure" />
              <div className="reasons__item_content">
                <h3 className="reasons__item_title">Hybrid Training</h3>
                <p className="reasons__item_text">
                  <b style={{ color: "rgb(0, 107, 149)" }}>Blended Approach</b>
                </p>
                <p>
                  Combine the benefits of on-site and online training for a
                  comprehensive learning experience.
                </p>
                <b style={{ color: "rgb(0, 107, 149)" }}>Adaptable Solutions</b>
                <p>
                  {" "}
                  Customize the training format to suit your team's needs and
                  availability.
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bannerL">
      <div className="container-md">
        <div className="bannerL__container">
          <div className="bannerL__wrapper">
            <h2 className="bannerL__title">
              Unlock the Full Potential of Salesforce
            </h2>
            <h5 className="bannerL__subtitle">
              Partner with NimboNexus Pvt Ltd for expert Salesforce Training
              Services and empower your team to achieve excellence. Contact us
              today to discuss your training needs and start your journey towards
              mastering Salesforce.
            </h5>
            <div className="bannerL__button button button-outline contactModalButton">
              <a
                href="/contact-us"
                style={{ textDecoration: "none", color: "white" }}
              >
                Book a Call
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default Training;