import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import "./contact-us.css";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_lc8lblw', // Replace with your Email.js Service ID
            'template_nf60xhp', // Replace with your Email.js Template ID
            formData,
            '2iq2YcirJyqvsoJO2' // Replace with your Email.js User ID
        ).then((response) => {
            alert('Email sent successfully!');
            setFormData({ name: '', email: '', phone: '', subject: '', message: '' }); // Clear form
        }).catch((error) => {
            alert('Failed to send the email. Please try again later.');
        });
    };

    return (
      <div className="contact-form-container">
      <div className="innerf">
        <div className="contact-form-left">
          <h2>CONTACT US</h2>
          <h3>Streamline Your Business Operations With Our Salesforce Implementation Solutions!</h3>
          </div>

                <div className="contact-form-right">
                    <h2>Fill out the form and we will contact you</h2>
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group">
                                <input type="text" id="name" name="name" placeholder="Your Name*" value={formData.name} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <input type="email" id="email" name="email" placeholder="Email Address*" value={formData.email} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <input type="tel" id="phone" name="phone" placeholder="Phone Number*" value={formData.phone} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <select id="subject" name="subject" value={formData.subject} onChange={handleChange} required >
                                    <option value="">Choose Your Interest</option>
                                    <option value="Hire Salesforce Developer">Hire Salesforce Developer</option>
                                    <option value="Salesforce Consulting Service">Salesforce Consulting Service</option>
                                    <option value="Salesforce Training">Salesforce Training</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <textarea id="message" name="message" placeholder="Message *" value={formData.message} onChange={handleChange} required style={{ height: '80px' }} spellCheck="false"
                            ></textarea>
                        </div>
                        <button type="submit" className="submit-button">
                            Book A Free Consultation
                        </button>
                    </form>
                    <p style={{ fontSize: '16px', color: 'white' }}>Or, Reach Out To Us At</p>
              <p><a href="mailto:contact@nimbonexus .in" style={{ color: 'white', textDecoration: 'none', fontSize: '24px' }}>contact@NimboNexus .in</a></p>
        
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
