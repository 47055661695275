import React, { useState, useEffect }  from "react";
import { Link } from "react-router-dom";
import history from '../../common/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import "./navbar.css";
import "./mobileNavbar.css";

library.add(faBars);

function Navbar() {
    const [isNavbarWhite, setIsNavbarWhite] = useState(false);
    const [currentWidth, setCurrentWidth] = useState('');
    const [isMobileNavbarVisible, setIsMobileNavbarVisible] = useState(false);
    const [mobileNavDrillDown, setMobilenavDrillDown] = useState(""); 

    useEffect(() => {
        setCurrentWidth(window.screen.width);
        document.addEventListener("scroll", handleScrollEvent);
        return () => {
            document.removeEventListener("scroll", handleScrollEvent);
        }
    }, [])

    const handleScrollEvent = (e) => {
        const headerNavbar = document.getElementById("header-navbar");
        if(window.scrollY > 50 && !headerNavbar?.classList.contains("scrolled")) {
            headerNavbar.classList.add("scrolled");
            setIsNavbarWhite(true);
        }
        else if (window.scrollY <= 50 && headerNavbar.classList.contains("scrolled")) {
            headerNavbar.classList.remove("scrolled");
            setIsNavbarWhite(false)
        }
        
    }

    const handleHamburgerClick = () => {
        setIsMobileNavbarVisible(!isMobileNavbarVisible);
    }

    const handleMobileNavbarClick = (drilldownId) => {
        setMobilenavDrillDown(mobileNavDrillDown === drilldownId ? "" : drilldownId);
    }

    return (
        <header className="header" id="header-navbar">
            <div className="header__wrapper" >
                <div className="header__logo">
                    <a href="/">
                        <picture>
                            <source media="(min-width: 768px)" srcSet={isNavbarWhite ? "/images/lastlogo.png" : "/images/lastlogo.png"}></source>
                            <img src={isNavbarWhite ? "/images/lastlogo.png" : "/images/lastlogo.png"} />
                        </picture>
                    </a>
                </div>

                { currentWidth > 768 ?
                    <nav className="desktop-nav">
                        <section className="content" id="scrlTop">
                            <header className="header ">
                                <div className="header__wrapper">
                                    <Link className="header__logo" to="/"  style={{textDecoration: "none"}}>
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={isNavbarWhite ? "/images/lastlogo.png" : "/images/lastlogo.png"}></source>
                                            <img src={isNavbarWhite ? "/images/lastlogo.png" : "/images/lastlogo.png"} />
                                        </picture>
                                    </Link>

                                    <div className="nav">
                                        <div className="nav__wrapper">
                                            <div className="nav__dropdown">
                                                <Link className="navLink nav__dropdown_title color-white" to="/"  style={{textDecoration: "none"}}>Home</Link>
                                            </div>

                                            {/* <div className="nav__dropdown">
                                                <Link className="navLink nav__dropdown_title color-white" to="/Blog"  style={{textDecoration: "none"}}>Blogs</Link>
                                            </div> */}

                                            <div className="nav__dropdown" data-id="dropNav1">
                                                <div className="nav__dropdown_title">
                                                    Services
                                                </div>
                                                <div className="navContent-wrap nav__dropdown_content" id="dropNav1">
                                                    <div className="navContent mod-row3 mod-column-txt">
                                                        <Link className="navContent-item navLink" to="/salesforce"  style={{textDecoration: "none"}}>
                                                            Salesforce Consulting Service
                                                            <span >Support and development</span>
                                                        </Link>

                                                        <Link className="navContent-item navLink" to="/development"  style={{textDecoration: "none"}}>
                                                            Salesforce Custom Development
                                                            <span >Launch own Salesforce app</span>
                                                        </Link>

                                                        <Link className="navContent-item navLink" to="/training"  style={{textDecoration: "none"}}>
                                                            Salesforce Training
                                                            <span >Launch own Salesforce app</span>
                                                        </Link>

                                                        <Link className="navContent-item navLink" to="/training"  style={{textDecoration: "none"}}>
                                                            ReactJs Development
                                                            <span >Launch own React app</span>
                                                        </Link>

                                                        <Link className="navContent-item navLink" to="/training"  style={{textDecoration: "none"}}>
                                                            Hire A Resource
                                                            <span ></span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="nav__dropdown">
                                                <Link className="navLink nav__dropdown_title color-white" to="/about-us"  style={{textDecoration: "none"}}>About Us</Link>
                                            </div>

                                            <div className="nav__dropdown">
                                                <Link className="navLink nav__dropdown_title color-white" to="/Contact"  style={{textDecoration: "none"}}>Contact Us</Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </header>
                        </section>
                    </nav> :
                    <div className="hamburger" onClick={handleHamburgerClick}> 
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                }
                

            </div>

            <div className={`mobile-nav ${isMobileNavbarVisible ? "mobile-nav--open" : ""}`}>
                <div className="mobile-nav__wrapper">
                    <div className="mobile-nav__dropdown" data-id="dropNav1">
                        <Link to="/">
                            <div className="mobile-nav__dropdown-title">Home</div>
                        </Link>
                    </div>

                    <div className="mobile-nav__dropdown" data-id="dropNav2">
                        <div className="mobile-nav__dropdown-title" onClick={() => handleMobileNavbarClick("dropNav2")}>
                            Services
                            <span className="mobile-nav__dropdown-icon ">▼</span>
                        </div>
                        <div className={`mobile-nav__dropdown-content ${mobileNavDrillDown === "dropNav2" ? "active": ""}`} id="dropNav2">
                            <Link className="mobile-nav__content-item mobile-nav__link" to="/salesforce">Salesforce Consulting Service</Link>
                            <Link className="mobile-nav__content-item mobile-nav__link" to="/development">Salesforce Custom Development</Link>
                            <Link className="mobile-nav__content-item mobile-nav__link" to="/training">Salesforce Training</Link>
                        </div>
                    </div>

                    <div className="mobile-nav__dropdown" data-id="dropNav3">
                        <Link to="/about-us">
                            <div className="mobile-nav__dropdown-title">About Us</div>
                        </Link>
                    </div>

                    <div className="mobile-nav__dropdown" data-id="dropNav4">
                        <Link to="/contact-us">
                            <div className="mobile-nav__dropdown-title">Contact Us</div>
                        </Link>
                    </div>

                </div>
            </div>
        </header>
    )
}

export default Navbar;