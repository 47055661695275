import React from 'react';
import "./contact.css";

const Contact = () => {
    return (
        <div>
            <div className="contact-section">
                <div className="about-us-banner">
                    <div className="banner-container">
                    <picture>
                        <source media="(min-width: )" srcset="/images/contact.jpg" />
                        <img src="/images/contact.jpg" alt="Contact Us" />
                    </picture>
                        <div className="banner-content-overlay">
                            <h1>Contact Us</h1>
                            <p>At NimboNexus Pvt Ltd, we value open communication and are always here to assist you.</p>
                        </div>
                    </div>
                </div>

                <div className="contact-info-container">
                    <div className="contact-details">
                        <h2>Get in Touch</h2>
                        <p><strong>Office Address:</strong><br />NimboNexus Pvt Ltd<br />Office 301 and FC-1, Anchor Mall, Ajmer Rd, Madrampur, Ganpati Nagar, Jaipur, Rajasthan-302006</p>
                        <p><strong>Phone:</strong></p>
                        <p>INDIA: +91 7976066296</p>
                        <p><strong>Email:</strong> contact@NimboNexus.in</p>
                        <p><strong>Business Hours:</strong><br />Monday - Friday: 9:00 AM - 6:00 PM<br />Saturday - Sunday: Closed</p>
                    </div>
                    {/* <div className="connect-with-us" style={{ backgroundColor: 'black' }}>
                        <h2>Connect With Us</h2>
                        <p>Follow Us on Social Media:</p>
                        <ul className="social-links">
                            <li>
                                <a href="https://www.linkedin.com/company/NimboNexus-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-linkedin fa-2x"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/NimboNexus.in" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook fa-2x"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/NimboNexus.in/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram fa-2x"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://x.com/NimboNexusIndia" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-x-twitter fa-2x"></i>
                                </a>
                            </li>
                        </ul>
                        <p style={{ color: 'white' }}>Stay updated with our latest news, updates, and job openings by following us on social media.</p>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Contact;
