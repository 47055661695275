import React from "react";

import "./ourservice.css";

export default function Services() {
    return (
        <div className="service">
            <p style={{color: "grey"}}>Things that we are expert in</p>
            <h2 style={{color: "black"}}> Our Services</h2>
            <div className="four-parts-container">
                <div className="part show">
                    <div className="part-images">
                        <img src="/images/HireDeveloper.png" alt="Part 1" className="part-image" />
                    </div>
                    <h3 className="part-heading">Hire a Salesforce Developer</h3>
                    <p className="part-text">If you want to improve your Salesforce capabilities, you have come to the correct place. Our team of trained and exceptionally competent Salesforce developers can help you in creating customized solutions.</p>
                </div>
                <div className="part show">
                    <div className="part-images">
                        <img src="/images/salesforceconsultingnew.png" alt="Part 2" className="part-image" />
                    </div>
                    <h3 className="part-heading">Salesforce Consulting</h3>
                    <p className="part-text">At Nimbonexus Technologies, we provide professional Salesforce consulting services to support organizations in making the best possible use of this dynamic cloud platform.</p>
                </div>
                <div className="part show">
                    <div className="part-images">
                        <img src="/images/Salesforce_Integration.png" alt="Part 3" className="part-image" />
                    </div>
                    <h3 className="part-heading">Salesforce Integration Services</h3>
                    <p className="part-text">Our company specializes in providing companies of every sort Salesforce Integration Services. Our team of professionals has years of expertise connecting Salesforce with other platforms and programmes to boost productiveness.</p>
                </div>
            </div>
        </div>
    )
}