import React from "react";

import "./aboutus.css";

function AboutCompany() {
    return (
        <div className="about-nimbonexus-container">
            <div className="about-nimbonexus-content">
                <div className="about-nimbonexus-image">
                    <img src="/images/team.jpg" alt="Image Alt Text" />
                </div>
                <div className="about-nimbonexus-text">
                    <p style={{color: "grey", marginBottom: -10, paddingBottom: 0}}>Welcome to Nimbonexus</p>
                    <h2 className="centered-heading" style={{textAlign: "left", color: "black"}}>About Us</h2>
                    <p>At NimboNexus Technologies, we offer innovative solutions to help businesses streamline their operations and enhance their processes. Our services are tailored to meet the specific needs of each client and include Salesforce deployment and customization, app development, data integration, and automation. We ensure your Salesforce implementation is perfectly suited to your business requirements, develop robust applications to enhance your business capabilities, and integrate your data seamlessly while automating processes to increase efficiency. Additionally, we provide continuous assistance and training to help you maximize the value of your investment. Our design team stays up-to-date with the latest trends and best practices, creating visually appealing and user-friendly interfaces that maintain brand consistency. Committed to excellence, we go the extra mile to help businesses thrive within the Salesforce ecosystem.</p>
                </div>
            </div>
        </div>
    )
}

export default AboutCompany;