import React from "react";
import Navbar from "../components/header/navigation/Navbar";
import Footer from "../components/footer/Footer";
import Salesforce from "../components/Salesforce/Salesforce";
import ContactUs from "../components/contact-us/ContactUs"

function SalesforcePage() {
    return (
        <>
            <Navbar />
            <Salesforce />
            <ContactUs />
            <Footer />
        </>
    );
}

export default SalesforcePage;
