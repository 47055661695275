import React, { useState, useEffect } from "react";

import "./headerslider.css";
import "./banner.css";

function Carouselbar() {
    const [activeHomeBanner, setActiveHomeBanner] = useState(0);

    useEffect(() => {
        const allSlides = document.getElementsByClassName("slide");
        
    }, [])

    const handleBannerLeftShift = () => {
        const allSlides = document.getElementsByClassName("slide");
        if(activeHomeBanner !== 0) {
            const nextActiveBanner = activeHomeBanner - 1;
            allSlides[nextActiveBanner].classList.add("active");
            allSlides[activeHomeBanner].classList.remove("active");
            setActiveHomeBanner(nextActiveBanner);
        }
        else {
            const nextActiveBanner = allSlides.length - 1;
            allSlides[nextActiveBanner].classList.add("active");
            allSlides[activeHomeBanner].classList.remove("active");
            setActiveHomeBanner(nextActiveBanner);
        }
    }

    const handleBannerRightShift = () => {
        const allSlides = document.getElementsByClassName("slide");
        if(activeHomeBanner !== (allSlides.length - 1)) {
            const nextActiveBanner = activeHomeBanner + 1;
            allSlides[nextActiveBanner].classList.add("active");
            allSlides[activeHomeBanner].classList.remove("active");
            setActiveHomeBanner(nextActiveBanner);
        }
        else {
            const nextActiveBanner = 0;
            allSlides[nextActiveBanner].classList.add("active");
            allSlides[activeHomeBanner].classList.remove("active");
            setActiveHomeBanner(nextActiveBanner);
        }
    }

    return (
        <div className="slider-container">
            <div className="slider">
                <div className="slide active">
                    <div className="slide-content">
                        <h1>
                            Proud to be the <br />#1 Ranked <span className="highlight">Salesforce</span>
                            <br />Consulting Company
                        </h1>
                    </div>
                </div>
                <div className="slide ">
                    <div className="slide-content">
                        <h1>
                            We Provide <br />Salesforce <span className="highlight">Training</span>
                            <br />&amp; Certifications <br />
                        </h1>
                    </div>
                </div>
                <button className="slide-btn prev" onClick={handleBannerLeftShift} >❮</button>
                <button className="slide-btn next" onClick={handleBannerRightShift} >❯</button>
            </div>
            {/* <div className="slider-info">
                    <div className="word-container1">
                        <div className="word" style={{color: "white"}}>
                            <h2>Design</h2>
                        </div>
                    </div>
                    <div className="word-container2">
                        <div className="word" style={{color: "rgb(0, 107, 149)"}}>
                            <h2>Development</h2>
                        </div>
                    </div>
                    <div className="word-container3">
                        <div className="word" style={{color: "white"}}>
                            <h2>Marketing</h2>
                        </div>
                    </div>
                    <div className="word-container4">
                        <div className="word" style={{color: "rgb(0, 107, 149)"}}>
                        <h2>Agency</h2></div>
                    </div>
                </div> */}
        </div>
    )
}

export default Carouselbar;